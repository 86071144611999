import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { usePalisadeData } from "hooks/contentful/pages/usePalisadeData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import TabbedNav from "components/TabbedNav/TabbedNav"
import PalisadeCardA from "components/PalisadeCards/PalisadeCardA"
import PalisadeCardB from "components/PalisadeCards/PalisadeCardB"
import PalisadeCardC from "components/PalisadeCards/PalisadeCardC"
import PalisadeCardD from "components/PalisadeCards/PalisadeCardD"
import PalisadeCardE from "components/PalisadeCards/PalisadeCardE"
import PalisadeCardF from "components/PalisadeCards/PalisadeCardF"
import PalisadeCardG from "components/PalisadeCards/PalisadeCardG"
import PalisadeCardH from "components/PalisadeCards/PalisadeCardH"

import "../styles/main.scss"
import styles from "../styles/pages/key-studies-palisade.module.scss"

const REFERENCES = [
  "PALFORZIA [package insert]. Lenoir, NC: Greer Laboratories, Inc.",
  "The PALISADE Group of Clinical Investigators: Vickery BP, Vereda A, Casale TB, et al. N Engl J Med. 2018;379:1991-2001.",
  "The PALISADE Group of Clinical Investigators: Vickery BP, Vereda A, Casale TB, et al. Protocol. N Engl J Med. 2018;379:1991-2001.",
  "Deschildre A, Elegbédé CF, Just J, et al. Clin Exp Allergy. 2016;46:610-620.",
  "The PALISADE Group of Clinical Investigators: Vickery BP, Vereda A, Casale TB, et al. Supplementary Appendix. N Engl J Med. 2018;379(suppl):1991-2001.",
]

const Palisade = ({ data, location }) => {
  const {
    header,
    tabs,
    cardA,
    cardB,
    cardC,
    cardD,
    cardE,
    cardF,
    cardG,
    cardH,
    referencesHeader,
  } = usePalisadeData(data.page)

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout
        showSeeResultsMenu={true}
        referencesHeader={referencesHeader}
        references={REFERENCES}
      >
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.content}>
          <PageTitle {...header}></PageTitle>
          <TabbedNav hash={location.hash} tabs={tabs} />
          <PalisadeCardA {...cardA} />
          <PalisadeCardB {...cardB} />
          <PalisadeCardC {...cardC} />
          <PalisadeCardD {...cardD} />
          <PalisadeCardG {...cardG} />
          <PalisadeCardE {...cardE} />

        </main>
      </Layout>
    </StateProvider>
  )
}

export default Palisade

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Palisade" }) {
      label
      url
      title
      description
      header {
        ...ReferencedTitleFragment
      }
      content {
        ...MenuFragment
        ...PalisadeCardAFragment
      }
      referencesHeader {
        ...ReferencedTitleFragment
      }
    }
  }
`
